import React, { Component } from 'react'
import './ContactPicture.css'

class ContactPicture extends Component {
  state = { hovered: {} }
  hovered = place => {
    const { hovered } = this.state
    return hovered[place]
  }
  hover = place =>
    this.setState(({ hovered }) => ({
      hovered: { ...hovered, [place]: true },
    }))
  
  blur = place => 
  this.setState(({ hovered }) => ({
    hovered: { ...hovered, [place]: false },
  }))

  render() {
    const { stats } = this.props
    return (
      <div className=""> 
        <div className="row m-0 team-leading-row">
          {stats.map(
            ({
              src,
              placename,
              sameplacename,
              add1,
              add2,
              add3,
              add4,
              add5,
              add6,
              add7,
              add8,
              add9,
            }) => (
              <div
                key={placename + sameplacename + src}
                className="col-lg-4 col-md-4 col-sm-4 col-xs-12 mb-4"
              >
                <div className="our-location">
                  <div key={placename} className="pic">
                    <img className="img-fluid" src={src} alt={placename} />
                    <div className="team-content">
                      {!this.hovered(placename) && (
                        <div className="team-info">
                          <h3 className="title">{placename}</h3>
                        </div>
                      )}
                      <div
                        onMouseOver={() => this.hover(placename)}
                        onMouseOut={() => this.blur(placename)}
                        className={
                          this.hovered(placename)
                            ? 'display-content'
                            : 'hidden-content'
                        }
                      >
                        <div className="contact-address-title">
                          {sameplacename}
                        </div>
                        <div className="contact-address">
                          {add1}
                          <br />
                          {add2}
                          <br />
                          {add3}
                          <br />
                          {add4}
                          <br />
                          {add5}
                          <br />
                          {add6}
                          <br />
                          {add7}
                          <br />
                          {add8}
                          <br />
                          {add9}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    )
  }
}

export default ContactPicture

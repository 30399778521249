import React, { Component } from 'react'
import fetch from 'unfetch'
import { FaChevronRight } from 'react-icons/fa'
import styled from 'styled-components'
import Recaptcha from 'react-recaptcha'
import Snackbar from '../Snackbar'
import './ContactForm.css'

const Form = styled.form`
  width: 100%;
  padding: 0% 5%;
  margin: 0;
  height: 60vh;
`
const Fieldset = styled.fieldset`
  position: relative;
  border: none;
`

const Label = styled.label`
  position: absolute;
  top: 18px;
  color: rgba(0, 0, 0, 0.3);
  transform-origin: left;
  transition: all 0.3s ease;
`

const SubmitButton = styled.button`
  position: relative;
  float: right;
  width: 50%;
  min-width: 120px;
  margin-top: 20px;
  padding: 6px 10px;
  color: #ed1f51;
  background: white;
  background-size: 200% 100%;
  background-position: 100% 0;
  transition: all 0.6s ease;
  font-family: Uniformcondensed-black;
  font-size: 16px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 2px;
  border: 1.5px solid #ed1f51;

  &:hover {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: rgba(237, 31, 81, 0.07);
    border: solid 1.5px #ed1f51;
    cursor: pointer;
    color: #ed1f51;
    outline: none;
    background-position: 0 0;
  }

  &:active,
  &:focus {
    outline: none;
    background-position: 0 0;
    color: #4d4d4d;
  }
`

class ContactForm extends Component {
  state = {
    comment: '',
    firstName: '',
    lastName: '',
    company: '',
    formSubmitted: false,
    isVerified: false,
  }
  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }
  render() {
    return (
      <div className='page'>
      <div className="row">
        
          

          <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
          <Form
          
          onSubmit={e => {
            e.preventDefault()
            const { isVerified } = this.state
            if (isVerified) {
              fetch(
                'https://0f7phaxd6g.execute-api.us-east-1.amazonaws.com/postprod',
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': '4LeLxeECAP78N4TyFklYN9jyIRJwYODN1TyKEcDC',
                  },
                  body: JSON.stringify({ ...this.state }),
                }
              ).finally(() => {
                this.setState(
                  {
                    comment: '',
                    firstName: '',
                    lastName: '',
                    company: '',
                    formSubmitted: true,
                  },
                  () => {
                    this.timeout = setTimeout(() => {
                      this.setState({ formSubmitted: false })
                    }, 4000)
                  }
                )
              })
            }
          }}
          autocomplete="off"
        >

          <div className='row'>
            <div className='col-sm-6'>
            <Fieldset>
              <input
                id="first"
                type="text"
                name="first"
                value={this.state.firstName}
                required
                onChange={e => this.setState({ firstName: e.target.value })}
              />
              <Label htmlFor="first">First Name</Label>
              <div className="after" />
            </Fieldset>
            </div>
            <div className='col-sm-6'>
              <Fieldset>
              <input
                id="last"
                type="text"
                name="last"
                value={this.state.lastName}
                required
                onChange={e => this.setState({ lastName: e.target.value })}
              />
              <Label htmlFor="last">Last Name</Label>
              <div className="after" />
            </Fieldset>
            </div>
          </div>
            
          
            <Fieldset>
              <input
                id="company"
                type="text"
                name="company"
                required
                value={this.state.company}
                onChange={e => this.setState({ company: e.target.value })}
              />
              <Label htmlFor="company">Company</Label>
              <div className="after" />
            </Fieldset>
            
            <Fieldset>
              <textarea
                id="Message"
                type="text"
                name="Message"
                rows="20"
                required
                value={this.state.comment}
                onChange={e => this.setState({ comment: e.target.value })}
              />
              <Label htmlFor="Message">Comments</Label>
              <div className="after" />
            </Fieldset>
            <div className='d-flex flex-column align-items-center'>
              <div style={{ marginTop: "10px" }}>
                <Recaptcha
                  sitekey="6LeWcHkUAAAAAPanYcPh1Xr1QoXEtkkwjCKYVZRo"
                  verifyCallback={() => this.setState({ isVerified: true })}
                />
              </div>
            <Fieldset className='submit-wrapper'>
              <SubmitButton>
                Submit
                <i>
                  <FaChevronRight />
                </i>
              </SubmitButton>
            </Fieldset>
            </div>
            

            </Form>

          </div>
          
          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 pagesubtitle">
          WORK WITH US! FOR OPEN POSITION LISTINGS, PLEASE CHECK{' '}
          <a
            href="https://www.linkedin.com/company/fairfest-media-limited/"
            target="_blank"
            rel="noopener noreferrer"
          >
            HERE
          </a>
          .
            
          </div>
        {/* <div className="inner-text-contact bottomtxt1 col-md-12">
         
        </div> */}
        <Snackbar display={this.state.formSubmitted} message="Form Submitted" />
      </div>
      </div>
    )
  }
}

export default ContactForm

import React from 'react'
import './PageTitle.css'

const PageTitle = ({ pagetitle, pagesubtitle }) => (
  <div className="page">
    <div className="pagetitle">{pagetitle}</div>
    <div className="pagesubtitle">{pagesubtitle}</div>
  </div>
)

export default PageTitle

import React from 'react'
import styled from 'styled-components'
const Wrapper = styled.div`
  transition: all 450ms ease-in-out;
  position: absolute;
  bottom: 0px;
  transform: translateY(${({ translate }) => translate});
`
const Inner = styled.div`
  background-color: tomato;
  padding: 10px 30px;
  margin-left: 30px;
  border-radius: 5px;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.5);
  color: white;
  font-weight: bold;
  z-index: 9000;
`
class Snackbar extends React.Component {
  render() {
    return (
      <Wrapper translate={this.props.display ? '-50px' : '0px'}>
        {this.props.display && <Inner>{this.props.message}</Inner>}
      </Wrapper>
    )
  }
}
export default Snackbar

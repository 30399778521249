import React from 'react'
import KOLKATA from '../images/KOLKATA.png'
import MUMBAI from '../images/mumbai.jpg'
import NEWDELHI from '../images/delhi.png'
import BENGALURU from '../images/bengaluru.png'
import AHMEDABAD from '../images/ahmedabad.png'
import HYDERABAD from '../images/HYDERABAD.png'
import PageTitle from '../components/PageTitle'
import ContactPicture from '../components/ContactPicture'
import ContactForm from '../components/ContactForm'
import Footer from '../components/Footer'
import Layout from '../layouts'
import Scroller from '../components/Scroll'

const ContactUs = () => (
  <Layout>
    <Scroller sections={2}>
      <Scroller.Sections>
        <Scroller.Section className="mobile-default-scroll">
          <div className="row m-0 contact-first-page">
            <div className="col-sm-12 pt-5">
              <div className="mb-3 mt-5">
                <PageTitle pagetitle="CONTACT US" pagesubtitle="" />
              </div>
              <ContactPicture
                stats={[
                  {
                    src: KOLKATA,
                    placename: 'KOLKATA',
                    sameplacename: 'KOLKATA',
                    add1: '74/2, AJC Bose Road, Tirupati Plaza,',
                    add2: '4C, 4th Floor, Kolkata 700 016',
                    add3: 'Tel: +91 33 4028 4028',
                    // add8: '2479 0010-14 (5 lines)',
                    // add9: 'Toll Free: 18001214008',
                    add4: '',
                    add5: 'Registered Address',
                    add6: '25 C/1, Belvedere Road, Alipur,',
                    add7: 'Kolkata 700 027',
                    add8: 'Tel: +91 33 4600 6699',
                    add9: 'Fax: +91 (033) 2479 0019',
                  },
                  {
                    src: MUMBAI,
                    placename: 'MUMBAI',
                    sameplacename: 'MUMBAI',
                    add1: 'Corporate Office',
                    add2: '305, 3rd Floor,',
                    add3: 'The Summit Business Bay,',
                    add4: 'Near WEH Metro Station,',
                    add5: 'Off Andheri - Kurla Road,',
                    add6: 'Mumbai 400093',
                    add7: 'Tel: +91 22 4555 8555',
                  },
                  {
                    src: NEWDELHI,
                    placename: 'NEW DELHI',
                    sameplacename: 'NEW DELHI,',
                    add1: 'U-1, Green Park Main,',
                    add2: 'New Delhi 110 016',
                    add3: 'Tel: +91 11 4615 0947',
                  },
                  {
                    src: BENGALURU,
                    placename: 'BENGALURU',
                    sameplacename: 'BENGALURU',
                    add1: '2454, 17th ‘E’ Cross,',
                    add2: '9th Main,Ground Floor,',
                    add3: 'Banashankri,',
                    add4: '2nd Stage, Bangalore 560 070',
                    add5: 'Tel: +91 80 2676 1598',
                    add6: 'Fax: +91 (080) 26761577',
                  },
                  {
                    src: AHMEDABAD,
                    placename: 'AHMEDABAD',
                    sameplacename: 'AHMEDABAD',
                    add1: 'Room 204, Eternia Complex,',
                    add2: 'Behind City Center,',
                    add3: 'Swastik Cross Road, Navrangpura,',
                    add4: 'Ahmedabad 380009',
                    add5: 'Tel: +91 79 2646 1217',
                    add6: 'Fax: +91 (079) 26461214',
                  },
                ]}
              />
          <div className="contact-second-page mt-5" id="contact-second">
            <div className="col-sm-12 p-0">
              <PageTitle
                pagetitle="ASK US ANYTHING"
                pagesubtitle="FOR ANY BOOKING AND COMPANY RELATED INFORMATION, PLEASE WRITE TO US BELOW."
              />
              <ContactForm />
            </div>
          </div>
          <Footer />
            </div>
          </div>
        </Scroller.Section>
      </Scroller.Sections>
    </Scroller>
  </Layout>
)

export default ContactUs
